@media print{
  @page {
margin-top: 0;
margin-bottom: 0;
  }
  body{
      padding-top: 24px;
      padding-bottom: 24px;
  }
  section{
  padding: 80px 0;
  background-color: aliceblue;
}
h1{
  font-size: 18px;
  
}
p{
  font-size: 6px;
}
}